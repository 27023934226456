.contact {
    .label {
        font-weight: 900;
    }
    
    a {
        color: color(body-link);
        
        &:hover {
            color: color(body-link-hover);
        }
    }
}